import React from 'react'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '@/shared/Layout'
import SEO from '@/shared/seo'
import 'twin.macro'

interface ArticleTemplate {
  data: {
    markdownRemark: {
      id: string
      excerpt: string
      html: string
      frontmatter: {
        title: string
        description: string
        thumbnail: any
      }
    }
  }
}

const PostPage: React.FC<ArticleTemplate> = ({ data }) => {
  const { title, description, thumbnail } = data.markdownRemark.frontmatter
  const src = getSrc(thumbnail)
  return (
    <Layout>
      <SEO lang="ja" title={title} description={description} image={src} />
      <p tw="mt-24">{title}</p>
    </Layout>
  )
}

export default PostPage

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
